:root {
    --grey: rgb(63, 63, 63);
    --white: white;
    --teal: rgb(6, 196, 225);
    --dark-blue: rgb(39, 43, 81);
    --light-grey: rgb(240, 240, 240);
    --badge-bg: var(--teal);
    --badge-color: var(--white);
    --button-bg: var(--dark-blue);
    --button-hover-bg: var(--teal);
    --button-color: var(--white);
}

body {
    line-height: 1.6;
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--white);
    color: var(--grey);
    zoom: 1;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--grey);
    color: var(--white);
    padding: 1px;
}

.header-card {
    background-color: var(--teal);
    color: var(--grey);
    padding: 1px 20px;
    border-radius: 5px;
}

nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    margin-right: 15px;
}

nav a {
    color: var(--teal);
    text-decoration: underline;
    font-weight: 200;
}

nav a:hover {
    color: var(--white);
}

/* Headings (h1, h2, h3, h4) */
h1, h2, h3, h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

h1 {
    font-size: 1rem;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.0rem;
}

/* Headings (h1, h2, h3, h4) */
h4 {
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden;    /* Ensures any overflow content is hidden */
    text-overflow: ellipsis; /* Adds ellipsis (...) at the end of the truncated text */
    max-width: 100%;    /* Adjust the width to fit within your container */
    position: relative; /* Make sure the h4 is positioned for the tooltip */
    z-index: 9999;      /* Ensures the tooltip is not hidden behind other elements */
    pointer-events: auto !important; /* Override pointer events to ensure interaction */
}

/* Tooltip is triggered by the title attribute (handled by the browser) */
/* Tooltip styling is handled by the browser, so no custom styles are required */
.video-item h4:hover {
    cursor: pointer;
}


p {
    margin: 0 0 1rem;
    line-height: 1.0;
}

/* Search Bar */
.search-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 5px;
}

.search-container input {
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
}

.search-button {
    padding: 8px 12px;
    font-size: 1rem;
    background-color: var(--teal);
    color: var(--white);
    border: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.search-button:hover {
    background-color: var(--dark-blue);
}

/* Video Grid */
.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 15px;
    padding: 3px 15px;
}

.video-item {
    background-color: var(--white);
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
    text-align: center;
    position: relative;
    transition: box-shadow 0.3s ease;
}

.video-item img {
    max-width: 100%;
    height: auto;
    border-radius: 0px;
}

/* Video Player Styling */
.video-player {
    position: relative;
    width: 60%;
    height: 45vh;
    background-color: #000;
    margin: 0 auto;
}

.video-player-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    object-fit: contain;
}

/* Tags Styling */
.video-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2px auto;
    width: 80%;
    padding: 2px;
}

.tag-badge {
    background-color: var(--badge-bg);
    color: var(--badge-color);
    padding: 3px 10px;
    border-radius: 8px;
    font-size: .9rem;
    margin: 6px;
}

/* Add Tags Button */
.add-tag-button {
    display: inline-block;
    background-color: var(--button-bg);
    color: var(--button-color);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-right: 15px;
    transition: background-color 0.3s ease;
}

.add-tag-button:hover {
    background-color: var(--button-hover-bg);
}

/* Tag Form Styling */
.tag-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.tag-form input {
    padding: 8px;
    margin-bottom: 8px;
    font-size: 0.9rem;
    border: 1px solid var(--grey);
    border-radius: 4px;
    width: 180px;
}

.tag-form button {
    background-color: var(--button-bg);
    color: var(--button-color);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.tag-form button:hover {
    background-color: var(--button-hover-bg);
}

/* Pagination Controls */
.pagination-controls {
    display: flex;
    justify-content: center;
    margin: 8px 0;
}

.pagination-controls button {
    margin: 0 3px;
    padding: 8px 18px;
    background-color: var(--teal);
    color: var(--white);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

footer {
    display: flex;
    justify-content: space-between;
    padding: 3px 16px;
    background-color: var(--grey);
    color: var(--white);
}
